import React, {useState,useEffect}  from "react"
import Image from "../components/image"
import SEO from "../components/seo"
import firebase from "../components/firebase"
import { navigate } from "gatsby-link";
import "../assets/admin_login.css"
const AdminLogin = () =>  {

  const [passCode, setPassCode] = useState("")


  const Login = (e) => {
    e.preventDefault();

      const unsubscribe = firebase
      .firestore()
      .collection("admin")
      .onSnapshot(snapshot => {
            const listItems = snapshot.docs.map(doc => ({
              ...doc.data(),
            }));
            if(passCode.passCode==listItems[0].password){
              if (typeof window !== 'undefined') {
                localStorage.setItem("adminUser",true)
                navigate(`/admin`)
              }                
            }else{
              console.log("wrong",passCode.passCode,listItems[0].password)
            }
          });
          return () => unsubscribe();

  }
  
    return (
      <div className="admin_login">
        <form className="admin_login_form" onSubmit={Login}>
          <input type="text" placeholder="Admin Password" onChange={e=>setPassCode({...passCode,passCode:e.currentTarget.value})}/>
          <button>
            Log In
          </button>
        </form>
      </div>
    )

}

export default AdminLogin